<template>
  <v-app>
    <v-app-bar app fixed color="white" elevate-on-scroll>
      <v-toolbar-title v-if="!isIFrame">
        <a :href="ecoskillsUrl">
          <img :src="logoUrl" height="50px" />
        </a>
        <v-divider vertical inset class="mx-4"></v-divider>

        <a href="https://correlaid.org/en">
          <img :src="`${publicPath}correlaid.png`" height="45px" />
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        class="hidden-sm-and-down"
        v-if="!isIFrame"
        text
        :to="{ name: 'trainings' }"
        >{{ $t("trainings") }}</v-btn
      >
      <v-btn
        v-if="!isIFrame"
        class="hidden-sm-and-down"
        text
        :to="{ name: 'question', params: { id: 1 } }"
      >
        {{ $t("assessment.title") }}
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!isIFrame"
            class="hidden-md-and-up"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{ name: 'trainings' }">
            <v-list-item-title>
              {{ $t("trainings") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'question', params: { id: 1 } }">
            <v-list-item-title>
              {{ $t("assessment.title") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text rounded v-bind="attrs" v-on="on">
            {{ locale }}
          </v-btn>
        </template>
        <v-list rounded>
          <v-list-item
            v-for="(item, index) in languages.filter(l => l !== locale)"
            :key="index"
            @click="changeLocale(item)"
          >
            <v-list-item-title>{{ item.toUpperCase() }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-content>
      <v-alert type="error" tile v-if="error">
        {{ $t("error.connectionDatabase") }}
      </v-alert>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-content>
    <v-footer v-if="!isIFrame" padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>
          <a href="http://www.umweltprofis.ch/">WWW.UMWELTPROFIS.CH </a> OdA
          Umwelt | Eichistrasse 1 | CH-6055 Alpnach Dorf |
          <a href="mailto:info@odaumwelt.ch">info@odaumwelt.ch</a> | Tel. +41 41
          671 00 69
        </strong>
        <a href="https://umweltprofis.ch/de/ueber-uns/disclaimer/"
          >Disclaimer</a
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    publicPath: process.env.BASE_URL,
    languages: ["de", "fr"]
  }),
  computed: {
    error() {
      return this.$store.getters["app/error"];
    },
    isIFrame() {
      return window.location !== window.parent.location;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    ecoskillsUrl() {
      return this.locale === "de"
        ? "https://umweltprofis.ch"
        : "https://ecoprofessions.ch";
    },
    logoUrl() {
      return this.locale === "de"
        ? `${this.publicPath}logo.webp`
        : `${this.publicPath}logo_fr.png`;
    }
  },
  created() {
    // load training data
    this.$store.dispatch("trainings/loadProviders");
    this.$store.dispatch("trainings/loadOffers");
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    }
  }
};
</script>
