const questions = [
  {
    question: {
      de: "Über welche Bildungsabschlüsse verfügst Du?",
      fr: "Quels diplômes avez-vous obtenu?",
    },
    placeholder: {
      de: "Bitte wähle einen oder mehrere Abschlüsse aus.",
      fr: "Veuillez sélectionner un ou plusieurs diplômes.",
    },
    label: {
      de: "Abschluss",
      fr: "Diplôme",
    },
    questionId: "1",
    previousQuestionId: null,
    isMultiple: true,
    answers: (store) => store.getters["trainings/degreesRequired"],
    nextId: (router) => {
      return router.push({ name: "question", params: { id: 2 } });
    },
  },
  {
    question: {
      de: "Welche Sektoren der Umweltwirtschaft interessieren Dich am meisten?",
      fr:
        "Quels sont les secteurs de l'économie de l'environnement qui vous intéressent le plus?",
    },
    placeholder: {
      de: "Bitte wähle mindestens einen Sektor aus.",
      fr: "Veuillez sélectionner au moins un secteur.",
    },
    label: {
      de: "Sektor",
      fr: "Secteur",
    },

    questionId: "2",
    previousQuestionId: "1",
    isMultiple: true,
    answers: (store) => store.getters["trainings/jobFields"],
    nextId: (router) => router.push({ name: "question", params: { id: 3 } }),
  },
  {
    question: {
      de: "In welchen Berufsfeldern möchtest Du in Zukunft arbeiten?",
      fr:
        "Dans quels domaines professionnels aimeriez-vous travailler à l'avenir?",
    },
    label: {
      de: "Berufsfeld",
      fr: "Domaines professionnels",
    },
    placeholder: {
      de: "Bitte wähle die Berufselder aus, die Dich am meisten interessieren.",
      fr:
        "Veuillez sélectionner les domaines professionnels qui vous intéressent le plus.",
    },

    questionId: "3",
    previousQuestionId: "2",
    isMultiple: true,
    answers: (store) => store.getters["trainings/jobFieldsZihlmann"],
    nextId: (router) => router.push({ name: "question", params: { id: 4 } }),
  },
  {
    question: {
      de: "Was für Tätigkeiten möchtest du gerne ausführen?",
      fr: "Quel genre d'activités aimeriez-vous avoir?",
    },
    label: {
      de: "Tätigkeit",
      fr: "Genre d'activités",
    },
    placeholder: {
      de: "Bitte wähle mindestens eine Tätigkeit aus.",
      fr: "Veuillez sélectionner au moins une activité.",
    },

    questionId: "4",
    previousQuestionId: "3",
    isMultiple: true,
    answers: (store) => store.getters["trainings/jobInterestsEgloff"],
    nextId: (router) => router.push({ name: "question", params: { id: 5 } }),
  },
  {
    question: {
      de:
        "In welcher Sprache möchtest Du eine Aus- oder Weiterbildung absolvieren?",
      fr:
        "Dans quelle langue souhaitez-vous suivre une formation ou formation continue?",
    },
    label: {
      de: "Sprache",
      fr: "Langue",
    },
    placeholder: {
      de: "Bitte wähle mindestens eine Sprache aus.",
      fr: "Veuillez sélectionner au moins une langue.",
    },
    questionId: "5",
    previousQuestionId: "4",
    isMultiple: true,
    answers: (store) => store.getters["trainings/languages"],
    nextId: (router) => router.push({ name: "result" }),
  },
];

export default questions;
