<template>
  <div class="home">
    <v-container class="mt-10">
      <v-breadcrumbs :items="navigation" large class="pl-0">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <h1 class="mb-5">{{ $t("trainings") }}</h1>
      <trainings-table
        :trainings="trainings"
        :isFilterable="true"
      ></trainings-table>
    </v-container>
  </div>
</template>

<script>
// import components
import TrainingsTable from "@/components/TrainingsTable.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    TrainingsTable
  },
  computed: {
    ...mapGetters("trainings", {
      trainings: "trainings"
    }),
    navigation() {
      return [{ text: this.$t("trainings"), disabled: true }];
    }
  }
};
</script>
