<template>
  <div>
    <v-row v-if="isFilterable">
      <!-- Selection filter -->
      <v-col cols="12" md="6">
        <!-- format -->
        <v-select
          v-model="format"
          :items="formats(locale)"
          :loading="isLoading"
          multiple
          :label="$t('trainingsTable.format')"
          outlined
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleFormat">
              <v-list-item-action>
                <v-icon v-if="format.length === 0">
                  mdi-check-box-multiple-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="format.length === 0">{{
                  $t("trainingsTable.doesNotMatter")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  $t("trainingsTable.reset")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ format.length - 1 }} {{ $t("trainingsTable.other") }})</span
            >
          </template>
        </v-select>
      </v-col>
      <!-- degree -->
      <v-col cols="12" md="6">
        <v-select
          v-model="degree"
          :items="degrees(locale)"
          :loading="isLoading"
          multiple
          :label="$t('trainingsTable.degree')"
          outlined
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleDegree">
              <v-list-item-action>
                <v-icon v-if="degree.length === 0">
                  mdi-check-box-multiple-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="degree.length === 0">{{
                  $t("trainingsTable.doesNotMatter")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  $t("trainingsTable.reset")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ degree.length - 1 }} {{ $t("trainingsTable.other") }})</span
            >
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="6">
        <!-- language -->
        <v-select
          v-model="language"
          :items="languages(locale)"
          :loading="isLoading"
          multiple
          :label="$t('trainingsTable.headerLanguage')"
          outlined
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleLanguage">
              <v-list-item-action>
                <v-icon v-if="language.length === 0">
                  mdi-check-box-multiple-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="language.length === 0">{{
                  $t("trainingsTable.doesNotMatter")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  $t("trainingsTable.reset")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ language.length - 1 }}
              {{ $t("trainingsTable.other") }})</span
            >
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="6">
        <!-- region -->
        <v-select
          v-model="region"
          :items="regions(locale)"
          :loading="isLoading"
          multiple
          :label="$t('trainingsTable.headerRegion')"
          outlined
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleRegion">
              <v-list-item-action>
                <v-icon v-if="region.length === 0">
                  mdi-check-box-multiple-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="region.length === 0">{{
                  $t("trainingsTable.doesNotMatter")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  $t("trainingsTable.reset")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ region.length - 1 }} {{ $t("trainingsTable.other") }})</span
            >
          </template>
        </v-select>
      </v-col>

      <!-- jobField -->
      <v-col cols="12" md="6">
        <v-select
          v-model="jobField"
          :items="jobFields(locale)"
          :loading="isLoading"
          multiple
          :label="$t('trainingsTable.headerField')"
          outlined
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleJobField">
              <v-list-item-action>
                <v-icon v-if="jobField.length === 0">
                  mdi-check-box-multiple-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="jobField.length === 0">{{
                  $t("trainingsTable.doesNotMatter")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  $t("trainingsTable.reset")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ jobField.length - 1 }}
              {{ $t("trainingsTable.other") }})</span
            >
          </template>
        </v-select>
      </v-col>
      <!-- jobFieldZihlmann -->
      <v-col cols="12" md="6">
        <v-select
          v-model="jobFieldZihlmann"
          :items="jobFieldsZihlmann(locale)"
          :loading="isLoading"
          multiple
          :label="$t('trainingsTable.fieldZihlmann')"
          outlined
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleJobFieldZihlmann">
              <v-list-item-action>
                <v-icon v-if="jobFieldZihlmann.length === 0">
                  mdi-check-box-multiple-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="jobFieldZihlmann.length === 0">{{
                  $t("trainingsTable.doesNotMatter")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  $t("trainingsTable.reset")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >({{ jobFieldZihlmann.length - 1 }}
              {{ $t("trainingsTable.other") }})</span
            >
          </template>
        </v-select>
      </v-col>
      <!-- jobInterestEgloff -->
      <v-col cols="12" md="6">
        <v-select
          v-model="jobInterestEgloff"
          :items="jobInterestsEgloff(locale)"
          :loading="isLoading"
          multiple
          :label="$t('trainingsTable.interest')"
          outlined
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleJobInterestEgloff">
              <v-list-item-action>
                <v-icon v-if="jobInterestEgloff.length === 0">
                  mdi-check-box-multiple-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="jobInterestEgloff.length === 0">{{
                  $t("trainingsTable.doesNotMatter")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  $t("trainingsTable.reset")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ jobInterestEgloff.length - 1 }}
              {{ $t("trainingsTable.other") }})</span
            >
          </template>
        </v-select>
      </v-col>

      <!-- Search -->
      <v-col cols="12">
        <v-text-field
          v-model="search"
          :label="$t('trainingsTable.search')"
          append-icon="mdi-magnify"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Table -->
    <v-data-table
      :items="selectedTrainings"
      :headers="tableHeaders"
      :search="search"
      :loading="isLoading"
      @click:row="redirectToTraining"
      disable-sort
    >
      <!-- region column -->
      <template v-slot:item.region="{ item }">
        <v-chip
          v-for="(region, index) in item.regionen[locale]"
          :key="index"
          class="my-1"
        >
          {{ region }}
        </v-chip>
      </template>
      <!-- language column -->
      <template v-slot:item.sprachen="{ item }">
        <v-chip
          v-for="sprache in item.sprachen[locale]"
          :key="sprache"
          class="my-1"
        >
          {{ sprache }}
        </v-chip>
      </template>
      <!-- anbieter column -->
      <template v-slot:item.anbieter="{ item }">
        <v-chip
          v-for="(anbieter, index) in item.anbieter"
          :key="index"
          class="my-1"
        >
          {{ anbieter.name[0] }}
        </v-chip>
      </template>
      <!-- arbeitsweltBranche column -->
      <template v-slot:item.arbeitsweltBranche="{ item }">
        <v-chip
          v-for="arbeitsweltBranche in item.arbeitsweltBranche[locale]"
          :key="arbeitsweltBranche"
          class="my-1"
        >
          {{ arbeitsweltBranche }}
        </v-chip>
      </template>
      <!-- Preis column -->
      <template v-slot:item.preise="{ item }">
        <v-chip v-for="preis in item.preise" :key="preis" class="my-1">
          {{ preis }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import dependencies
import { mapState, mapGetters } from "vuex";

export default {
  name: "TrainingsTable",
  data: () => ({
    search: "",
    jobField: [],
    degree: [],
    language: [],
    region: [],
    format: [],
    jobInterestEgloff: [],
    jobFieldZihlmann: [],
  }),
  props: {
    trainings: Array,
    isFilterable: Boolean,
  },
  computed: {
    ...mapState("trainings", ["isLoading"]),
    ...mapGetters("trainings", {
      offers: "offers",
      jobFields: "jobFields",
      degrees: "degrees",
      languages: "languages",
      formats: "formats",
      regions: "regions",
      jobFieldsZihlmann: "jobFieldsZihlmann",
      jobInterestsEgloff: "jobInterestsEgloff",
    }),
    locale() {
      return this.$root.$i18n.locale;
    },
    tableHeaders() {
      return [
        {
          value: "titel",
          text: this.$t("trainingsTable.headerTitle"),
          width: "20%",
        },
        {
          value: "anbieter",
          text: this.$t("trainingsTable.headerProvider"),
          width: "10%",
        },
        {
          value: "region",
          text: this.$t("trainingsTable.headerRegion"),
          width: "10%",
        },

        {
          value: "arbeitsweltBranche",
          text: this.$t("trainingsTable.headerField"),
          width: "15%",
        },
        {
          value: "sprachen",
          text: this.$t("trainingsTable.headerLanguage"),
          width: "10%",
        },
        {
          value: "preise",
          text: this.$t("trainingsTable.headerPrices"),
          width: "10%",
        },
      ];
    },
    selectedTrainings() {
      if (!this.isFilterable) return this.trainings;

      return this.trainings
        .filter((o) => this.format.includes(o.format[this.locale]))
        .filter((o) => this.degree.includes(o.abschluss[this.locale]))
        .filter(
          (o) =>
            o.sprachen[this.locale].length === 0 ||
            o.sprachen[this.locale].some((l) => this.language.includes(l))
        )
        .filter(
          (o) =>
            o.regionen[this.locale].length === 0 ||
            o.regionen[this.locale].some((l) => this.region.includes(l))
        )
        .filter((o) =>
          o.arbeitsweltBranche[this.locale].some((ab) =>
            this.jobField.includes(ab)
          )
        )
        .filter((o) =>
          o.berufsinteressenEgloff[this.locale].some((ab) =>
            this.jobInterestEgloff.includes(ab)
          )
        )
        .filter((o) =>
          o.berufsfeldZihlmann[this.locale].some((ab) =>
            this.jobFieldZihlmann.includes(ab)
          )
        );
    },
  },
  watch: {
    locale() {
      this.jobField = this.jobFields(this.locale);
      this.degree = this.degrees(this.locale);
      this.language = this.languages(this.locale);
      this.region = this.regions(this.locale);
      this.format = this.formats(this.locale);
      this.jobInterestEgloff = this.jobInterestsEgloff(this.locale);
      this.jobFieldZihlmann = this.jobFieldsZihlmann(this.locale);
    },
    offers: {
      handler: function() {
        this.jobField = this.jobFields(this.locale);
        this.degree = this.degrees(this.locale);
        this.language = this.languages(this.locale);
        this.region = this.regions(this.locale);
        this.format = this.formats(this.locale);
        this.jobInterestEgloff = this.jobInterestsEgloff(this.locale);
        this.jobFieldZihlmann = this.jobFieldsZihlmann(this.locale);
      },
      deep: true,
    },
  },
  methods: {
    redirectToTraining(item) {
      this.$router.push({ name: "training", params: { id: item.id } });
    },
    toggleJobFieldZihlmann() {
      if (this.jobFieldZihlmann.length > 0) {
        this.jobFieldZihlmann = [];
      } else {
        this.jobFieldZihlmann = this.jobFieldsZihlmann(this.locale);
      }
    },
    toggleJobInterestEgloff() {
      if (this.jobInterestEgloff.length > 0) {
        this.jobInterestEgloff = [];
      } else {
        this.jobInterestEgloff = this.jobInterestsEgloff(this.locale);
      }
    },
    toggleRegion() {
      if (this.region.length > 0) {
        this.region = [];
      } else {
        this.region = this.regions(this.locale);
      }
    },
    toggleJobField() {
      if (this.jobField.length > 0) {
        this.jobField = [];
      } else {
        this.jobField = this.jobFields(this.locale);
      }
    },
    toggleDegree() {
      if (this.degree.length > 0) {
        this.degree = [];
      } else {
        this.degree = this.degrees(this.locale);
      }
    },
    toggleLanguage() {
      if (this.language.length > 0) {
        this.language = [];
      } else {
        this.language = this.languages(this.locale);
      }
    },
    toggleFormat() {
      if (this.format.length > 0) {
        this.format = [];
      } else {
        this.format = this.formats(this.locale);
      }
    },
  },
  created() {
    this.jobField = this.jobFields(this.locale);
    this.degree = this.degrees(this.locale);
    this.language = this.languages(this.locale);
    this.format = this.formats(this.locale);
    this.region = this.regions(this.locale);
    this.jobInterestEgloff = this.jobInterestsEgloff(this.locale);
    this.jobFieldZihlmann = this.jobFieldsZihlmann(this.locale);
  },
};
</script>
