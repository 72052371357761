// import questions
import questions from "../data/questions";

const state = {
  answers: [],
  questions: questions
};

const getters = {
  answers: state => state.answers,
  questions: state => state.questions
};

const mutations = {
  commitAnswers: (state, answers) => (state.answers = answers)
};

const actions = {
  setAnswers: (context, answers) => context.commit("commitAnswers", answers)
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
