import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;
Vue.use(VueI18n);
// Ready translated locale messages
const messages = {
  de: {
    trainings: "Bildungsangebote",
    trainingsTable: {
      other: "Andere",
      reset: "Auswahl zurücksetzen",
      doesNotMatter: "Egal",
      headerTitle: "Titel",
      headerProvider: "Anbieter",
      headerLanguage: "Sprache",
      headerField: "Arbeitswelt/Branche",
      headerRegion: "Region",
      headerPrices: "Preise",
      format: "Format",
      degree: "Abschluss",
      interest: "Berufsinteresse",
      fieldZihlmann: "Berufsfeld",
      search: "Suche"
    },
    trainingView: {
      summary: "Zusammenfassung",
      content: "Inhalt",
      provider: "Anbieter",
      region: "Region",
      format: "Format",
      language: "Sprache / Sprachen",
      price: "Preis",
      contact: "Kontakt",
      ects: "ECTS",
      scope: "Umfang",
      duration: "Dauer",
      none: "Keine",
      degree: "Abschluss",
      sponsorInstitution: "Träger",
      directConnectionOptions: "Direkte Anschlussmöglichkeiten",
      educationStage: "Bildungsstufe",
      requirements: "Zulassungsbedingungen",
      degreesRequired: "Zur Zulassung berechtigende Abschlüsse",
      degreesRequiredSpecific: "Zulassungsbestimmungen spezifischer Abschluss",
      degreesRequiredTopic: "Zulassungsbestimmungen Abschluss Themenfelder",
      experienceRequired: "Zulassungsbedingungen Berufserfahrung",
      goToWebsite: "Zur Website",
      noAddressAvailable: "Keine Adresse vorhanden"
    },
    assessment: {
      title: "Assessment",
      questionContinue: "Weiter",
      of: "von",
      none: "Keine",
      all: "Alle",
      doesNotMatter: "Egal",
      questionBack: "Vorherige Frage",
      question: "Frage",
      result: "Ergebnis"
    },
    error: {
      connectionDatabase: "Verbindung zur Datenbank ist gestört."
    }
  },
  fr: {
    trainings: "Offres de formation",
    trainingsTable: {
      other: "Autres",
      reset: "Réinitialiser la sélection",
      doesNotMatter: "Peu importe",
      headerTitle: "Titre",
      headerProvider: "Prestataire",
      headerLanguage: "Langue",
      headerField: "Domaine professionnel/Branche",
      headerRegion: "Région",
      headerPrices: "Prix",
      format: "Format",
      degree: "Diplôme",
      interest: "Intérêt professionnel",
      fieldZihlmann: "Secteur d’activité",
      search: "Recherche"
    },
    trainingView: {
      summary: "Résumé",
      content: "Contenu",
      provider: "Prestataire",
      region: "Région",
      format: "Format",
      language: "Langue(s)",
      price: "Prix",
      contact: "Contact",
      ects: "ECTS",
      scope: "Etendue",
      duration: "Durée",
      none: "Aucune",
      degree: "Diplôme",
      sponsorInstitution: "Organe responsable",
      directConnectionOptions: "Possibilités d’accès direct",
      educationStage: "Niveau d'éducation",
      requirements: "Conditions d'admission",
      degreesRequired: "Diplômes donnant accès à la formation ",
      degreesRequiredSpecific: "Conditions d'admission Diplôme spécifique",
      degreesRequiredTopic:
        "Conditions d'admission Domaines thématiques des diplômes",
      experienceRequired: "Conditions d'admission Expérience professionnelle",
      goToWebsite: "Lien vers le site web",
      noAddressAvailable: "Aucune information disponible"
    },
    assessment: {
      title: "Evaluation",
      none: "Aucune",
      all: "Tous/Toutes",
      doesNotMatter: "Peu importe",
      of: "de",
      questionContinue: "Question suivante",
      questionBack: "Question précédente",
      question: "Question",
      result: "Résultat"
    }
  }
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "de", // set locale
  messages // set locale messages
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
