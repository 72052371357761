// import dependencies
import Airtable from "airtable";
import store from "../index";
import config from "@/api.json";

const base = new Airtable({ apiKey: config.api_key }).base("appxJYoa8RW4L9VcE");

const state = {
  isLoading: false,
  offers: [],
  providers: [],
};

const getters = {
  offers: (state) => state.offers,
  isLoading: (state) => state.isLoading,
  trainings: (state) =>
    // loop over all offers and modify each
    state.offers.map((offer) => {
      // add list of anbieter as field
      offer.anbieter = state.providers.filter((p) =>
        p.angebotId.some((id) => id === offer._id)
      );

      // for each offer, add list of regions by pulling from anbieter
      offer.regionen = {
        de: [
          ...new Set(
            offer.anbieter
              .map((anbieter) =>
                anbieter.region.de ? anbieter.region.de : ["Nicht definiert"]
              )
              .flat(1)
              .map((region) => region.trim())
          ),
        ],
        fr: [
          ...new Set(
            offer.anbieter
              .map((anbieter) =>
                anbieter.region.fr ? anbieter.region.fr : ["Nicht definiert"]
              )
              .flat(1)
              .map((region) => region.trim())
          ),
        ],
      };

      // for each offer, add list of preise by pulling from anbieter
      offer.preise = [
        ...new Set(
          offer.anbieter
            .map((anbieter) =>
              anbieter.preis ? anbieter.preis : ["keine Angabe"]
            )
            .flat(1)
            .map((preis) => preis.trim())
        ),
      ];

      return offer;
    }),
  regions: (state) => (lang) => [
    ...new Set(
      state.providers
        .map((p) => (p.region[lang] ? p.region[lang] : ["Nicht definiert"]))
        .flat(1)
        .map((region) => region.trim())
    ),
  ],
  jobFields: (state) => (lang) => [
    ...new Set(
      state.offers
        .map((o) =>
          o.arbeitsweltBranche[lang]
            ? o.arbeitsweltBranche[lang]
            : ["Nicht definiert"]
        )
        .flat(1)
        .map((arbeitsweltBranche) => arbeitsweltBranche.trim())
    ),
  ],
  degreesRequired: () => (lang) => {
    let degrees = {
      de: [
        "Abgeschlossene obligatorische Schulzeit",
        "Lehre EBA",
        "Lehre EFZ",
        "Berufsmaturität",
        "Fachmaturität",
        "Gymnasiale Maturität",
        "Passarelle",
        "Eidg. Diplom",
        "Eidg. Diplom HF",
        "Eidg. Fachausweis",
        "Bachelor FH",
        "Bachelor of Business Administration",
        "Bachelor PH",
        "Bachelor Uni",
        "Master of Business Administration",
        "Master FH",
        "Master PH",
        "Master Uni",
        "Doktorat",
        "Weiterbildungen",
        "Keine",
      ],
      fr: [
        "Scolarité obligatoire achevée",
        "Apprentissage AFP",
        "Apprentissage CFC",
        "Maturité professionnelle",
        "Maturité spécialisée",
        "Maturité gymnasiale",
        "Passarelle",
        "Diplôme fédéral DF",
        "Diplôme ES",
        "Brevet fédéral",
        "Bachelor HES",
        "Bachelor of Business Administration",
        "Bachelor HEP",
        "Bachelor Uni",
        "Master HES",
        "Master HEP",
        "Master Uni",
        "Master of Business Administration",
        "Doctorat",
        "Formation continues",
        "Aucune",
      ],
    };
    return degrees[lang];
  },
  degrees: (state) => (lang) => [
    ...new Set(
      state.offers
        .map((o) => (o.abschluss[lang] ? o.abschluss[lang] : "Nicht definiert"))
        .map((abschluss) => abschluss.trim())
    ),
  ],
  jobFieldsZihlmann: (state) => (lang) => [
    ...new Set(
      state.offers
        .map((o) =>
          o.berufsfeldZihlmann[lang]
            ? o.berufsfeldZihlmann[lang]
            : ["Nicht definiert"]
        )
        .flat(1)
        .map((berufsfeldZihlmann) => berufsfeldZihlmann.trim())
    ),
  ],
  jobInterestsEgloff: (state) => (lang) => [
    ...new Set(
      state.offers
        .map((o) =>
          o.berufsinteressenEgloff[lang]
            ? o.berufsinteressenEgloff[lang]
            : ["Nicht definiert"]
        )
        .flat(1)
        .map((interesse) => interesse.trim())
    ),
  ],
  languages: (state) => (lang) => [
    ...new Set(
      state.providers
        .map((p) => (p.sprache[lang] ? p.sprache[lang] : ["Nicht definiert"]))
        .flat(1)
        .map((sprache) => sprache.trim())
    ),
  ],
  formats: (state) => (lang) => [
    ...new Set(
      state.offers.map((o) =>
        o.format[lang] === undefined ? ["Nicht definiert"] : o.format[lang]
      )
    ),
  ],
};

const mutations = {
  commitIsLoading: (state, isLoading) => (state.isLoading = isLoading),
  commitOffers: (state, data) => (state.offers = data),
  commitProviders: (state, data) => (state.providers = data),
};

const actions = {
  loadProviders: (context) => {
    store.dispatch("app/setError", false);

    context.commit("commitIsLoading", true);

    let providers = [];
    base("Anbieter")
      .select({
        pageSize: 100,
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          providers = [
            ...providers,
            ...records.map((r) => {
              let providerNeededVariables = {};
              providerNeededVariables.adresse = r.fields["Adresse"]
                ? r.fields["Adresse"].split("\n")
                : [];
              // anbieter who are not assocaited to an angebot -> no implication for our application

              providerNeededVariables.angebotId = r.fields["AngebotID"]
                ? r.fields["AngebotID"]
                : [];
              providerNeededVariables.id = r.fields["Eintragsnummer"];
              providerNeededVariables.email = r.fields["Email"];
              providerNeededVariables.region = {
                de: r.fields["Region"] ? r.fields["Region"] : [],
                fr: r.fields["Region FR"] ? r.fields["Region FR"] : [],
              };
              providerNeededVariables.name = r.fields["Name"];
              providerNeededVariables.sprache = {
                de: r.fields["Sprache"] ? r.fields["Sprache"] : [],
                fr: r.fields["Sprache FR."] ? r.fields["Sprache FR."] : [],
              };
              providerNeededVariables.preis = r.fields["Preis"];
              providerNeededVariables.telefon = r.fields["Telefon"];
              providerNeededVariables.website = r.fields["Website"];
              providerNeededVariables.traegerschaft = r.fields["Traegerschaft"];

              return providerNeededVariables;
            }),
          ];
          fetchNextPage();
        },
        function done(error) {
          context.commit("commitProviders", providers);
          context.commit("commitIsLoading", false);
          if (error) {
            store.dispatch("app/setError", error);
            return;
          }
        }
      );
  },
  loadOffers: (context) => {
    store.dispatch("app/setError", false);

    context.commit("commitIsLoading", true);

    let offers = [];
    base("Bildungsangebote")
      .select({
        pageSize: 100,
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          offers = [
            ...offers,
            ...records.map((r) => {
              let offerNeededVariables = {};
              offerNeededVariables._id = r.id;
              offerNeededVariables.id = r.fields["ID"];
              offerNeededVariables.anbieter = r.fields["Anbieter"]
                ? r.fields["Anbieter"]
                : [];
              offerNeededVariables.lead = r.fields["Lead"];
              offerNeededVariables.titel = r.fields["Titel"];
              offerNeededVariables.format = {
                de: r.fields["Format"] ? r.fields["Format"].trim() : "",
                fr: r.fields["Format FR"] ? r.fields["Format FR"].trim() : "",
              };
              offerNeededVariables.bildungsstufe = r.fields["Bildungsstufe"];
              offerNeededVariables.abschluss = {
                de: r.fields["Abschluss"] ? r.fields["Abschluss"].trim() : "",
                fr: r.fields["Abschluss FR"]
                  ? r.fields["Abschluss FR"].trim()
                  : "",
              };

              offerNeededVariables.inhalt = r.fields["Inhalt"]
                ? r.fields["Inhalt"].split("\n").map((s) => s.trim())
                : undefined;
              offerNeededVariables.ects = r.fields["ECTS"];
              offerNeededVariables.umfang = r.fields["Dauer"];
              offerNeededVariables.arbeitsweltBranche = {
                de: r.fields["Arbeitswelt / Branche"]
                  ? r.fields["Arbeitswelt / Branche"].map((s) => s.trim())
                  : [],
                fr: r.fields["Arbeitswelt / Branche FR"]
                  ? r.fields["Arbeitswelt / Branche FR"].map((s) => s.trim())
                  : [],
              };
              offerNeededVariables.zulassungsbedingungenAbschluss = {
                de: r.fields["Zulassungsbedingungen Abschluss"]
                  ? r.fields["Zulassungsbedingungen Abschluss"].map((s) =>
                      s.trim()
                    )
                  : [],
                fr: r.fields["Zulassungsbedingungen Abschluss FR"]
                  ? r.fields["Zulassungsbedingungen Abschluss FR"].map((s) =>
                      s.trim()
                    )
                  : [],
              };
              offerNeededVariables.zulassungsbedingungenBerufserfahrung =
                r.fields["Zulassungsbedingungen Berufserfahrung"];
              offerNeededVariables.zulassungsbestimmungenAbschlussThemenfelder =
                r.fields["Zulassungsbestimmungen Abschluss Themenfelder"];
              offerNeededVariables.zulassungsbestimmungenSpezifischerAbschluss =
                r.fields["Zulassungsbestimmungen Spezifischer Abschluss"];
              offerNeededVariables.direkteAnschlussmoeglichkeitenAbschluesse =
                r.fields["Direkte Anschlussmöglichkeiten Abschlüsse"];
              offerNeededVariables.berufsfeldZihlmann = {
                de: r.fields["Berufsfeld (Zihlmann)"]
                  ? r.fields["Berufsfeld (Zihlmann)"].map((s) => s.trim())
                  : [],
                fr: r.fields["Berufsfeld (Zihlmann) FR"]
                  ? r.fields["Berufsfeld (Zihlmann) FR"].map((s) => s.trim())
                  : [],
              };
              offerNeededVariables.berufsinteressenEgloff = {
                de: r.fields["Berufsinteressen (Egloff)"]
                  ? r.fields["Berufsinteressen (Egloff)"].map((s) => s.trim())
                  : [],
                fr: r.fields["Berufsinteressen (Egloff) FR"]
                  ? r.fields["Berufsinteressen (Egloff) FR"].map((s) =>
                      s.trim()
                    )
                  : [],
              };
              offerNeededVariables.sprachen = {
                de: [
                  ...new Set(
                    r.fields["Sprache"].map((sprache) => sprache.trim())
                  ),
                ],
                fr: [
                  ...new Set(
                    r.fields["Sprache.FR"].map((sprache) => sprache.trim())
                  ),
                ],
              };
              return offerNeededVariables;
            }),
          ];
          fetchNextPage();
        },
        function done(error) {
          context.commit("commitOffers", offers);
          context.commit("commitIsLoading", false);
          if (error) {
            store.dispatch("app/setError", error);
            return;
          }
        }
      );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
