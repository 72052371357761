import Vue from "vue";
import VueRouter from "vue-router";
import Trainings from "@/views/Trainings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "trainings" }
  },
  {
    path: "/bildungsangebote",
    name: "trainings",
    component: Trainings
  },
  {
    path: "/bildungsangebote/:id",
    name: "training",
    component: () =>
      import(/* webpackChunkName: "training" */ "@/views/Training.vue")
  },
  {
    path: "/frage/:id",
    name: "question",
    component: () =>
      import(/* webpackChunkName: "assessment" */ "@/views/Question.vue")
  },
  {
    path: "/ergebnis",
    name: "result",
    component: () =>
      import(/* webpackChunkName: "assessment" */ "@/views/Result.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
