var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFilterable)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.formats(_vm.locale),"loading":_vm.isLoading,"multiple":"","label":_vm.$t('trainingsTable.format'),"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleFormat}},[_c('v-list-item-action',[(_vm.format.length === 0)?_c('v-icon',[_vm._v(" mdi-check-box-multiple-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[(_vm.format.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.doesNotMatter")))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.reset")))])],1)],1),_c('v-divider')]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.format.length - 1)+" "+_vm._s(_vm.$t("trainingsTable.other"))+")")]):_vm._e()]}}],null,false,105327810),model:{value:(_vm.format),callback:function ($$v) {_vm.format=$$v},expression:"format"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.degrees(_vm.locale),"loading":_vm.isLoading,"multiple":"","label":_vm.$t('trainingsTable.degree'),"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleDegree}},[_c('v-list-item-action',[(_vm.degree.length === 0)?_c('v-icon',[_vm._v(" mdi-check-box-multiple-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[(_vm.degree.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.doesNotMatter")))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.reset")))])],1)],1),_c('v-divider')]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.degree.length - 1)+" "+_vm._s(_vm.$t("trainingsTable.other"))+")")]):_vm._e()]}}],null,false,3534403650),model:{value:(_vm.degree),callback:function ($$v) {_vm.degree=$$v},expression:"degree"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.languages(_vm.locale),"loading":_vm.isLoading,"multiple":"","label":_vm.$t('trainingsTable.headerLanguage'),"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleLanguage}},[_c('v-list-item-action',[(_vm.language.length === 0)?_c('v-icon',[_vm._v(" mdi-check-box-multiple-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[(_vm.language.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.doesNotMatter")))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.reset")))])],1)],1),_c('v-divider')]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.language.length - 1)+" "+_vm._s(_vm.$t("trainingsTable.other"))+")")]):_vm._e()]}}],null,false,3738406338),model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.regions(_vm.locale),"loading":_vm.isLoading,"multiple":"","label":_vm.$t('trainingsTable.headerRegion'),"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleRegion}},[_c('v-list-item-action',[(_vm.region.length === 0)?_c('v-icon',[_vm._v(" mdi-check-box-multiple-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[(_vm.region.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.doesNotMatter")))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.reset")))])],1)],1),_c('v-divider')]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.region.length - 1)+" "+_vm._s(_vm.$t("trainingsTable.other"))+")")]):_vm._e()]}}],null,false,1719733442),model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.jobFields(_vm.locale),"loading":_vm.isLoading,"multiple":"","label":_vm.$t('trainingsTable.headerField'),"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleJobField}},[_c('v-list-item-action',[(_vm.jobField.length === 0)?_c('v-icon',[_vm._v(" mdi-check-box-multiple-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[(_vm.jobField.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.doesNotMatter")))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.reset")))])],1)],1),_c('v-divider')]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.jobField.length - 1)+" "+_vm._s(_vm.$t("trainingsTable.other"))+")")]):_vm._e()]}}],null,false,3052084226),model:{value:(_vm.jobField),callback:function ($$v) {_vm.jobField=$$v},expression:"jobField"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.jobFieldsZihlmann(_vm.locale),"loading":_vm.isLoading,"multiple":"","label":_vm.$t('trainingsTable.fieldZihlmann'),"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleJobFieldZihlmann}},[_c('v-list-item-action',[(_vm.jobFieldZihlmann.length === 0)?_c('v-icon',[_vm._v(" mdi-check-box-multiple-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[(_vm.jobFieldZihlmann.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.doesNotMatter")))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.reset")))])],1)],1),_c('v-divider')]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("("+_vm._s(_vm.jobFieldZihlmann.length - 1)+" "+_vm._s(_vm.$t("trainingsTable.other"))+")")]):_vm._e()]}}],null,false,80574153),model:{value:(_vm.jobFieldZihlmann),callback:function ($$v) {_vm.jobFieldZihlmann=$$v},expression:"jobFieldZihlmann"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.jobInterestsEgloff(_vm.locale),"loading":_vm.isLoading,"multiple":"","label":_vm.$t('trainingsTable.interest'),"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleJobInterestEgloff}},[_c('v-list-item-action',[(_vm.jobInterestEgloff.length === 0)?_c('v-icon',[_vm._v(" mdi-check-box-multiple-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[(_vm.jobInterestEgloff.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.doesNotMatter")))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("trainingsTable.reset")))])],1)],1),_c('v-divider')]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.jobInterestEgloff.length - 1)+" "+_vm._s(_vm.$t("trainingsTable.other"))+")")]):_vm._e()]}}],null,false,631835138),model:{value:(_vm.jobInterestEgloff),callback:function ($$v) {_vm.jobInterestEgloff=$$v},expression:"jobInterestEgloff"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('trainingsTable.search'),"append-icon":"mdi-magnify","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.selectedTrainings,"headers":_vm.tableHeaders,"search":_vm.search,"loading":_vm.isLoading,"disable-sort":""},on:{"click:row":_vm.redirectToTraining},scopedSlots:_vm._u([{key:"item.region",fn:function(ref){
var item = ref.item;
return _vm._l((item.regionen[_vm.locale]),function(region,index){return _c('v-chip',{key:index,staticClass:"my-1"},[_vm._v(" "+_vm._s(region)+" ")])})}},{key:"item.sprachen",fn:function(ref){
var item = ref.item;
return _vm._l((item.sprachen[_vm.locale]),function(sprache){return _c('v-chip',{key:sprache,staticClass:"my-1"},[_vm._v(" "+_vm._s(sprache)+" ")])})}},{key:"item.anbieter",fn:function(ref){
var item = ref.item;
return _vm._l((item.anbieter),function(anbieter,index){return _c('v-chip',{key:index,staticClass:"my-1"},[_vm._v(" "+_vm._s(anbieter.name[0])+" ")])})}},{key:"item.arbeitsweltBranche",fn:function(ref){
var item = ref.item;
return _vm._l((item.arbeitsweltBranche[_vm.locale]),function(arbeitsweltBranche){return _c('v-chip',{key:arbeitsweltBranche,staticClass:"my-1"},[_vm._v(" "+_vm._s(arbeitsweltBranche)+" ")])})}},{key:"item.preise",fn:function(ref){
var item = ref.item;
return _vm._l((item.preise),function(preis){return _c('v-chip',{key:preis,staticClass:"my-1"},[_vm._v(" "+_vm._s(preis)+" ")])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }