// import dependencies
import Vue from "vue";
import Vuex from "vuex";

// import modules
import app from "./modules/app";
import assessment from "./modules/assessment";
import trainings from "./modules/trainings";

// initialize vuex
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    assessment,
    trainings
  }
});
