import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import de from "vuetify/es5/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#3b4252",
        secondary: "#2e3440",
        accent: "#88c0d0",
        error: "#bf616a",
        info: "#b48ead",
        success: "#a3be8c",
        warning: "#bf616a"
      }
    }
  },
  lang: {
    locales: { de },
    current: "de"
  }
});
