const state = {
  error: false
};

const getters = {
  error: state => state.error
};

const mutations = {
  commitError: (state, error) => (state.error = error)
};

const actions = {
  setError: (context, error) => context.commit("commitError", error)
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
